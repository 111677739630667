<template>
    <div :class="typeModifierClass" class="notice">
        <template v-if="hasIcon">
            <svg-icon v-if="type === 'success'" name="success" class="notice__icon" />
            <svg-icon v-if="type === 'info'" name="info" class="notice__icon" />
            <svg-icon v-if="type === 'error'" name="error" class="notice__icon" />
            <svg-icon v-if="type === 'warning'" name="warning" class="notice__icon" />
        </template>
        <div :class="contentClasses" class="notice__content">
            <h3 v-if="$slots['heading']" class="notice__heading"><slot name="heading" /></h3>
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // 'default' (same as null), 'info', 'success', 'warning' or 'error'
        type: {
            type: String,
            default: null,
        },
        showIcon: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        typeModifierClass() {
            let cssClass = this.type !== null ? `notice--${this.type}` : '';

            if (this.hasIcon) {
                cssClass += ' has-icon';
            }

            return cssClass;
        },
        contentClasses() {
            return {
                'has-heading': !!this.$slots.heading,
            };
        },
        hasIcon() {
            if (!this.showIcon) {
                return false;
            }

            return ['info', 'warning', 'error', 'success'].includes(this.type);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

$bg-default: $bright-blue-light;
$bg-success: $bright-blue;
$bg-warning: $secondary-lightest;
$bg-error: $white;
$bg-info: $cream-white;

$border-default: $bright-blue;
$border-success: $bright-blue;
$border-warning: $secondary-color;
$border-error: $warning;
$border-info: $cream-white-dark;

$icon-success: $blue;
$icon-warning: $secondary-dark;
$info-icon: $blue;

.notice {
    padding: 14px 20px;
    line-height: 24px;
    border-radius: $default-border-radius;
    background-color: $bg-default;
    border: 1px solid $border-default;
    color: $primary-text-color;
    text-align: left;
    @include font-size(16px);

    p:last-child {
        margin-bottom: 0;
    }

    &__heading {
        font-weight: bold;
    }

    &__icon.svg-icon {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        margin-right: 10px;
        margin-top: 1px;
    }

    &.has-icon {
        display: flex;
    }

    &__content {
        a {
            color: $primary-text-color;
        }
    }

    &--success {
        background-color: $bg-success;
        border-color: $border-success;
        color: $primary-text-color;

        .notice__icon {
            color: $icon-success;
        }
    }

    &--warning {
        background-color: $bg-warning;
        border-color: $border-warning;
        color: $primary-text-color;

        .notice__icon {
            color: $icon-warning;
        }
    }

    &--error {
        background-color: $bg-error;
        border-color: $border-error;
        color: $warning-darkest;

        // If there is a heading the text becomes primary text color
        .notice__content {
            &.has-heading {
                color: $primary-text-color;
                .notice__heading {
                    color: $warning-darkest;
                }
            }

            a {
                color: $warning-darkest;
            }
        }

        .notice__icon {
            color: $border-error;
        }
    }

    &--info {
        background-color: $bg-info;
        border-color: $border-info;
        color: $primary-text-color;

        .notice__icon {
            color: $info-icon;
        }
    }
}
</style>
