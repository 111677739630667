<template>
    <Location ref="location" v-model="location" :always-popup="alwaysPopup" :placeholder="placeholder" />
</template>

<script>
import { mapGetters } from 'vuex';
import Location from '~/components/form-elements/Location.vue';
import { getFilterValue, getDefaultFilterValue } from '~/helpers/queryParser';
import { isGeolocatedKey } from '~/config/filters';
import { LOCATION, PLACE_DESCRIPTION, COUNTRY } from '~/config/filterKeys';
import { actionTypes as filterActionTypes } from '~/store/filters';

export default {
    components: {
        Location,
    },
    props: {
        placeholder: {
            type: String,
            default() {
                return this.$t('search_form.location_placeholder');
            },
        },
        alwaysPopup: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters(['standByFilters']),
        location: {
            get() {
                const location =
                    this.standByFilters[LOCATION] !== undefined
                        ? this.standByFilters[LOCATION]
                        : getFilterValue(this.$route.query, LOCATION);

                if (location === getDefaultFilterValue(LOCATION)) {
                    return location;
                }

                let name =
                    this.standByFilters[PLACE_DESCRIPTION] !== undefined
                        ? this.standByFilters[PLACE_DESCRIPTION]
                        : getFilterValue(this.$route.query, PLACE_DESCRIPTION);
                let isGeolocated = false;

                if (name === isGeolocatedKey) {
                    name = this.$t('search_form.location_near_me');
                    isGeolocated = true;
                }

                return {
                    lat: location?.lat,
                    lng: location?.lng,
                    name,
                    isGeolocated,
                };
            },
            set(value) {
                let location = value;

                if (value !== null) {
                    location = {
                        lat: value?.lat,
                        lng: value?.lng,
                    };
                }

                const placeDescription = value === null ? null : value.name;
                const country = value === null ? null : value.country;

                this.$store.dispatch(filterActionTypes.COLLECT_STAND_BY_FILTERS, {
                    [LOCATION]: location || getDefaultFilterValue(LOCATION),
                    [PLACE_DESCRIPTION]: placeDescription,
                    [COUNTRY]: country,
                });

                this.$emit('location-change', value);
            },
        },
    },
    methods: {
        focusInput() {
            this.$refs.location.$refs.autocompleteInput.focus();
        },
    },
};
</script>
