<template>
    <div class="date-select-input datepicker-trigger" :class="`date-select-input--${inputType}`">
        <template v-if="!externalTriggerId">
            <a v-if="inputType === 'link'" :id="`datepicker-input-${uuid}`" class="date-select-input__link">
                <slot />
            </a>
            <button
                v-else-if="inputType === 'button'"
                :id="`datepicker-input-${uuid}`"
                type="button"
                class="button"
                :class="{ [`button--${buttonVariant}`]: true }"
            >
                {{ placeholder }}
            </button>
            <div v-else :id="`datepicker-input-${uuid}`">
                <DateRange
                    v-if="mode === 'range'"
                    :start="hasPreselectedRange ? tempDateOne : from"
                    :end="hasPreselectedRange ? tempDateTwo : to"
                    :with-focus="withFocus && isDatepickerOpen"
                    :is-datepicker-open="isDatepickerOpen"
                    :mode="inputType === 'simpleInput' ? 'input' : 'range'"
                    :dash-separator="inputType === 'simpleInput'"
                    :has-icon="true"
                />
                <div v-else class="input single-date" :class="{ 'single-date--modified': from !== initFrom }">
                    {{ from | dateFormat }}
                </div>
            </div>
        </template>

        <McDatepicker
            v-if="uuid"
            ref="datepicker"
            :options="datepickerOptions"
            :trigger-element-id="triggerElementId"
            :min-date="minDateWithFallback"
            :date-one="from"
            :date-two="to"
            :disabled-dates="disabledDates"
            :disabled-periods="unavailablePeriods"
            :rented-periods="rentedPeriods"
            :full-screen-mobile="fullScreenMobile"
            :offset-x="offsetX"
            :offset-y="offsetY"
            :start-open="startOpen"
            :months-to-show="monthsToShow"
            :mobile-months-to-show="mobileMonthsToShow"
            :has-preselected-range="hasPreselectedRange"
            :preselected-range-marked="preselectedRangeMarked"
            :high-season-min-booking-length="highSeasonMinBookingLength"
            :type="type"
            :low-season-min-booking-length="lowSeasonMinBookingLength"
            :has-tooltips="hasTooltips"
            class="date-select-input__datepicker"
            :mode="mode"
            :show-header="showHeader"
            :show-legend="showLegend"
            :show-action-buttons="showActionButtons"
            :disable-apply="disableApply"
            :hide-after-selection="hideAfterSelection"
            :is-in-modal="isInModal"
            :close-on-select="closeOnSelect"
            :reset-dates-on-close="resetDatesOnClose"
            @date-one-selected="dateOneSelected"
            @date-two-selected="dateTwoSelected"
            @temp-date-one="updateTempOne"
            @temp-date-two="updateTempTwo"
            @apply="handleApply"
            @cleared="handleClear"
            @opened="handleOpen"
            @closed="handleClose"
        >
            <template #pre-section>
                <slot name="pre-section" />
            </template>
            <template #after-section>
                <slot name="after-section" />
            </template>
        </McDatepicker>
    </div>
</template>

<script>
import startOfTomorrow from 'date-fns/startOfTomorrow';
import formatISO from 'date-fns/formatISO';
import isDate from 'date-fns/isDate';
import isBefore from 'date-fns/isBefore';
import McDatepicker from './datepicker/McDatepicker.vue';
import namedColors from '~/config/namedColors';
import DateRange from '~/components/DateRange.vue';
import { actionTypes as filterActionTypes } from '~/store/filters';
import { AVAILABLE_BETWEEN } from '~/config/filterKeys';
import { getDefaultFilterValue, buildQuery } from '~/helpers/queryParser';

export default {
    components: {
        McDatepicker,
        DateRange,
    },
    props: {
        mode: {
            type: String,
            default: 'range',
        },
        inputType: {
            type: String,
            default: 'input', // 'link', 'input' or 'button'
        },
        placeholder: {
            type: String,
            default() {
                return this.$t('date_picker.input_placeholder_default');
            },
        },
        hasTooltips: {
            type: Boolean,
            default: false,
        },
        hasPreselectedRange: {
            type: Boolean,
            default: false,
        },
        preselectedRangeMarked: {
            type: Boolean,
            default: false,
        },
        withFocus: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'popup',
        },
        initFrom: {
            type: String,
            default: null,
        },
        initTo: {
            type: String,
            default: null,
        },
        disabledDates: {
            type: Array,
            default: () => [],
        },
        unavailablePeriods: {
            type: Array,
            default: () => [],
        },
        rentedPeriods: {
            type: Array,
            default: () => [],
        },
        disableApply: {
            type: Boolean,
            default: false,
        },
        hideAfterSelection: {
            type: Boolean,
            default: false,
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        showLegend: {
            type: Boolean,
            default: false,
        },
        showActionButtons: {
            type: Boolean,
            default: true,
        },
        externalTriggerId: {
            type: String,
            default: null,
        },
        minDate: {
            type: [Object, Date, String],
            default: null,
        },
        // Offset position for the picker
        offsetX: {
            type: Number,
            default: null,
        },
        offsetY: {
            type: Number,
            default: null,
        },
        monthsToShow: {
            type: Number,
            default: 2,
        },
        mobileMonthsToShow: {
            type: Number,
            default: 4,
        },
        highSeasonMinBookingLength: {
            type: Number,
            default: null,
        },
        lowSeasonMinBookingLength: {
            type: Number,
            default: null,
        },
        options: {
            type: Object,
            default: () => {},
        },
        isInModal: {
            type: Boolean,
            default: false,
        },
        startOpen: {
            type: Boolean,
            default: false,
        },
        fullScreenMobile: {
            type: Boolean,
            default: true,
        },
        currency: {
            type: String,
            default: '',
        },
        // Date-format (applicable when inputType === 'dates')
        dateFormat: {
            type: String,
            default: 'd MMM',
        },
        // Whether to store selected dates in Vuex store
        storeDates: {
            type: Boolean,
            default: true,
        },
        addToQueryImmediately: {
            type: Boolean,
            default: false,
        },
        closeOnSelect: {
            type: Boolean,
            default: true,
        },
        resetDatesOnClose: {
            type: Boolean,
            default: true,
        },
        buttonVariant: {
            type: String,
            default: 'primary',
            validator(value) {
                return ['primary', 'secondary', 'secondary-contrast'].includes(value);
            },
        },
        backgroundColor: {
            type: String,
            default: '#fff',
        },
    },
    data() {
        return {
            uuid: null,
            from: this.initFrom,
            to: this.initTo,
            tempDateOne: null, // used just to display newly selected dates in datepicker input (only if hasPreselectedRange = true)
            tempDateTwo: null,
            isDatepickerOpen: false,
        };
    },
    computed: {
        triggerElementId() {
            return this.externalTriggerId || `datepicker-input-${this.uuid}`;
        },
        minDateWithFallback() {
            if (this.minDate) {
                return this.minDate;
            }

            return formatISO(startOfTomorrow(), { representation: 'date' });
        },
        datepickerOptions() {
            const customColors = this.options?.colors || {};
            const customTexts = this.options?.texts || {};

            return {
                colors: {
                    selected: namedColors.blue,
                    inRange: namedColors['bright-blue'],
                    hoveredInRange: namedColors['bright-blue'],
                    inRangeText: namedColors.primary,
                    disabled: '#eeeeee',
                    disabledText: namedColors.disabled,
                    text: namedColors.primary,
                    backgroundColor: this.backgroundColor,
                    ...customColors,
                },
                texts: customTexts,
            };
        },
    },
    watch: {
        initFrom(newVal) {
            this.from = newVal;
            this.$refs.datepicker?.updatePreselectedFromDate(this.from);
        },
        initTo(newVal) {
            this.to = newVal;
            this.$refs.datepicker?.updatePreselectedToDate(this.to);
        },
    },
    mounted() {
        this.uuid = Math.random().toString(16).substring(9);
    },
    methods: {
        handleOpen() {
            this.$emit('opened');
            this.isDatepickerOpen = true;
        },
        handleClose(type) {
            this.$emit('closed', type);
            this.isDatepickerOpen = false;
        },
        updateTempOne(date) {
            this.tempDateOne = date;
        },
        updateTempTwo(date) {
            this.tempDateTwo = date;
        },
        dateOneSelected(date) {
            this.from = date;

            if (this.mode === 'single') {
                this.$emit('input', this.from);
            }
        },
        dateTwoSelected(date) {
            this.to = date;
        },
        /**
         * Emit the values set from the datepicker (if they're both set)
         */
        handleApply(date1, date2) {
            this.from = date1;
            this.to = date2;
            this.tempDateOne = date1;
            this.tempDateTwo = date2;

            if (this.mode === 'range') {
                if (!this.from || !this.to) {
                    return;
                }
            } else if (!this.from) {
                return;
            }

            this.emitSelectedDates(this.from, this.to);
        },
        /**
         * This functions as "clear" rather than cancel
         */
        handleClear() {
            this.from = null;
            this.to = null;

            this.emitSelectedDates(this.from, this.to);
        },
        emitSelectedDates(from, to) {
            let dates = {
                from,
                to,
            };

            const fromDate = new Date(from);
            const toDate = new Date(to);

            // Make sure to is after from
            if (isDate(fromDate) && isDate(toDate) && isBefore(toDate, fromDate)) {
                // A swap is required
                dates = {
                    from: to,
                    to: from,
                };
            }

            if (!from || !to) {
                dates = getDefaultFilterValue(AVAILABLE_BETWEEN);
            }

            if (this.addToQueryImmediately) {
                const query = buildQuery(this.$route.query, {
                    [AVAILABLE_BETWEEN]: dates,
                });
                this.$router.replace({
                    query,
                });
            } else if (this.storeDates) {
                this.$store
                    .dispatch(filterActionTypes.COLLECT_STAND_BY_FILTERS, {
                        [AVAILABLE_BETWEEN]: dates,
                    })
                    .then(() => {
                        this.$emit('input', dates);
                    })
                    .catch(err => {
                        this.$sentry.captureException(err);
                    });
            } else {
                this.$emit('input', dates);
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.date-select-input {
    &--link {
        display: inline-block;
    }

    .single-date {
        color: $secondary-text-color;
        width: 100%;

        &:focus {
            border-color: $border-color !important;
        }

        &--modified {
            color: $primary;
        }
    }
}
</style>
