<template>
    <div class="date-range" :class="dateRangeModifiers" @click="$emit('click')">
        <svg-icon
            v-if="hasIcon"
            name="datepicker"
            class="date-range__datepicker-icon"
            :class="{ 'date-range__datepicker-icon--small': mode === 'range' }"
        />
        <span v-if="mode === 'input' && !withFocus && !start" class="date-range__main-placeholder">
            {{ $t('date_picker.desktop_heading') }}
        </span>
        <div v-else class="date-range__dates-wrapper">
            <span
                v-if="start"
                class="date-range__dates-wrapper__date"
                :class="{
                    'date-range__dates-wrapper__date--grayed': isDatepickerOpen,
                }"
            >
                {{ startDate }}
            </span>
            <span
                v-else
                class="date-range__dates-wrapper__placeholder"
                :class="{ 'date-range__dates-wrapper__date--focused': withFocus && !start && !end }"
            >
                {{ $t('date_picker.from') }}
            </span>
            <svg-icon v-if="!dashSeparator" name="arrow_right" class="date-range__dates-wrapper__separator" />
            <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
            <span
                v-else
                class="date-range__dates-wrapper__dash-separator"
                :class="{ 'date-range__dates-wrapper__dash-separator--focused': withFocus }"
            >
                -
            </span>
            <!-- eslint-enable @intlify/vue-i18n/no-raw-text -->
            <span
                v-if="end"
                class="date-range__dates-wrapper__date"
                :class="{
                    'date-range__dates-wrapper__date--grayed': isDatepickerOpen,
                    'date-range__dates-wrapper__placeholder--focused': withFocus && start,
                }"
                >{{ endDate }}</span
            >
            <span
                v-else
                class="date-range__dates-wrapper__placeholder"
                :class="{ 'date-range__dates-wrapper__placeholder--focused': withFocus && start && !end }"
            >
                {{ $t('date_picker.to') }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        start: {
            type: String,
            default: null,
        },
        end: {
            type: String,
            default: null,
        },
        hasIcon: {
            type: Boolean,
            default: false,
        },
        dashSeparator: {
            type: Boolean,
            default: false,
        },
        formatDates: {
            type: Boolean,
            default: true,
        },
        withFocus: {
            type: Boolean,
            default: true,
        },
        mode: {
            type: String,
            default: 'range',
            validator(value) {
                return ['range', 'read-only', 'input'].includes(value);
            },
        },
        isDatepickerOpen: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        dateRangeModifiers() {
            return {
                'date-range--range': this.mode === 'range',
                'date-range--read-only': this.mode === 'read-only',
                'date-range--input': this.mode === 'input',
            };
        },
        startDate() {
            return this.formatDates ? this.$options.filters.dateFormat(this.start) : this.start;
        },
        endDate() {
            return this.formatDates ? this.$options.filters.dateFormat(this.end) : this.end;
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.date-range {
    display: flex;
    align-items: center;
    border: 1px solid $border-color;
    border-radius: $default-border-radius;
    box-sizing: border-box;
    height: 48px;

    &__datepicker-icon {
        min-height: 24px;
        min-width: 24px;
        color: $primary;
        margin-right: 8px;

        &--small {
            min-height: 18px;
            min-width: 18px;
            margin-right: 16px;
        }

        @include xs {
            margin-right: 16px;
        }
    }

    &__dates-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 0 -8px;

        &__date,
        &__placeholder {
            @include font-size(14px);
            flex: 1;
            padding: 0 8px;
            white-space: nowrap;
            text-align: left;

            @include xs {
                @include font-size(16px);
            }
        }

        &__placeholder--focused,
        &__date--focused {
            box-shadow: 0 0 0 1px $primary, $default-shadow;
        }

        &__dash-separator--focused {
            visibility: hidden;
        }

        &__date {
            color: $primary-text-color;

            &--grayed {
                color: $secondary-text-color;
            }
        }

        &__placeholder {
            color: $secondary-text-color;
        }

        &__separator {
            display: inline-block;
            min-width: 24px;
            height: 20px;
            color: $primary;
            @media (min-width: 380px) {
                margin: 0 8px;
            }
        }
    }

    &__main-placeholder {
        padding: 12px 0;
        @include font-size(16px);
    }

    &--range {
        padding: 12px;
    }

    &--read-only {
        padding: 0;
        border: none;
        height: auto;
    }

    &--input {
        padding-left: 20px;

        .date-range__dates-wrapper {
            margin: 0;
        }

        .date-range__dates-wrapper__date,
        .date-range__dates-wrapper__placeholder {
            padding: 12px 10px;
            border-radius: $default-border-radius;
        }
    }
}
</style>
